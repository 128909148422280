/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --primary : #673AB7 ;
    --primaryHover : #5323a5 ;
    --lightSlate : #F8FAFD;
    --grayText : #9B98B8;
    --dark : #333;
    --darkSlate : #E7EAF3;
    --pure : #fff;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


#select-box-input{
    padding : 6px 0 !important;
    outline : none !important; 
}

.css-t3ipsp-control{
    border : none !important;
}

@media print {
    @page {
        margin-top: 20px; /* Adjust the margin values as per your requirements */
    }
    .report {
        padding : 2rem;
    }
    .report table {
        border : 1px solid var(--dark);
    }
    .report table td , .report table th {
        border : 1px solid gray;
    }
    
    .report-row {
        height : 95vh !important;
        page-break-inside: avoid !important;
    }
    .report-header {
        margin-top: 2rem !important;
    }

}