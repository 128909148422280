.header-left svg , .search-svg svg {
    stroke: rgb(165, 165, 165);
}

.header-right{
    display: flex;
    align-items: center;
    gap : 1rem;
}
.header-right > div {
    width: 30px;
    height : 30px;
    border-radius: 50%;
    box-shadow: 4px 4px 12px 0px #BBC3CE99;
    display: flex;
    justify-content: center;
    align-items: center;  
    cursor: pointer;  
}
.header-right > .hamburger {
    display: none;
}

.header-right > div svg {
    stroke : #5843BE;
}

@media (max-width: 768px){
    .header-right .hamburger{
        display: flex;
    }
}